nav{
    position: absolute;
    top: 0;
    width: 100%;
}

footer{
    position: absolute;
    width: 100%;
    bottom: 0;
}

.page-container{
    /*min-height: 90vh;*/
    /*background-color: red;*/
    /*padding: 120px 0;*/
    padding: 50px 0 100px;
}

.inner-page{
    margin: auto;
    max-width: 900px;
    padding: 20px;
}

.landing-page{
    /*background: url("../public/kgp_building2.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*background-position: center;*/
    /*filter: blur(8px);*/
    /*-webkit-filter: blur(8px);*/
}

.hero-img{
    width: 950px;
    height: 150px;
    border-radius: 8px;
    /*box-shadow: 0 0 10px rgba(0,0,0,0.5);*/
}

.hero-header{
    /*height: 200px;*/
    /*width: 900px;*/
    /*background-color: red;*/
    /*background: url("../public/kgp_building2.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*background-position: center;*/
    margin: 30px auto 20px;
    padding: 0;
}

.product-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    /*max-width: 900px;*/
    /*width: 100%;*/
    /*margin: 0 auto;*/
}

@media screen and (max-width: 700px) {
    .product-container{
        grid-template-columns: 1fr;
    }
}

.product-card-header{
    display: flex;
    flex-direction: row;
    text-align: left;
    /*margin: 10px;*/
}

.card-logo{
    height: 45px;
    width: 45px;
    border-radius: 8px;
}